





























































































































































































































































































































































































































































































































































































@import '~@/assets/styles/components/formularios';
@import '~@/assets/styles/components/modal-cadastro';
@import '~@/assets/styles/components/btn';

.modal-cadastro-resultado {
  .v-input__control{
    padding: 15px 0px;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details .v-messages {
    position: relative;
    bottom: -0px; 
  }

  .results-campo .v-input__slot {
    margin-bottom: 0 !important;
    min-height: 30px !important;
  }
    
  .modal-cadastro .v-card {
    border-radius: 0;
    box-shadow: none;
  }

  .modal-cadastro-titulo {
    padding: 0 36px 5px;
    font-size: 1em;
    color: #1cb4ff;
    text-transform: uppercase;
    font-weight: bold;

    &.fechado {
      color: #FFC107;
      padding-bottom: 0;
      text-align: center;
      display: block;
    }
  }

  box-shadow: none;
  border: none;
  border-radius: 0;
  
  .v-card {
    box-shadow: none !important;
    border: none !important;
    border-radius: 0 !important;
  }
}
